exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-concept-js": () => import("./../../../src/pages/concept.js" /* webpackChunkName: "component---src-pages-concept-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opening-hours-js": () => import("./../../../src/pages/opening-hours.js" /* webpackChunkName: "component---src-pages-opening-hours-js" */),
  "component---src-pages-pomodoro-js": () => import("./../../../src/pages/pomodoro.js" /* webpackChunkName: "component---src-pages-pomodoro-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */)
}

